<template>
	<div class="key-info">
		<div class="title">{{key.name}} Key</div>
		<div class="newKey" v-if="isNew">
			Congratulations!
			<br />You've earned a new key!
		</div>
		<div class="keyBox">
			<img class="keyImage" :src="key.img" />
		</div>
		<div class="header">This key is earned by:</div>
		<div class="info">{{key.howEarned}}</div>
		<div class="header">It unlocks:</div>
		<div class="info">{{key.unlocks}}</div>
		<div class="footer">
			<button class="goButton" @click="useKey()">Use Key</button>
		</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>
export default {
	name: "KeyInfo",
	data() {
		return {
			key: {},
			isNew: false
		};
	},
	created() {
		this.isNew = this.$route.query.isNew || false;
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.key = await this.$services.getOne("cleverKeys", this.$route.params.id);
			this.key.img = this.key.img.replace("./img/", "/img/")
		},
		useKey: async function () {
			// TODO: Update tab nav when routing
			// console.log("Before useKey: " + this.key.name);
			this.key = await this.$services.useKey(this.key);
			this.$eventHub.$emit('keys-updated')
			if (this.key.name == "Director") {
				this.$eventHub.$emit('select-tab', 'Settings');
				this.$router.push("/settings");
			}
			if (this.key.name == "Sherlock") {
				this.$eventHub.$emit('select-tab', 'Next');
				this.$router.push("/choice/next");
			}
			if (this.key.name == "Hat Trick") {
				this.$eventHub.$emit('select-tab', 'Choices');
				this.$router.push("/choice-list");
			}
			if (this.key.name == "Clean Slate") {
				this.$eventHub.$emit('select-tab', 'Choices');
				this.$router.push("/choice-list");
			}
			if (this.key.name == "Score" || this.key.name == "Method") {
				this.$eventHub.$emit('select-tab', 'Score');
				this.$router.push("/score");
			}
			if (this.key.name == "Tufte" || this.key.name == "Driller") {
				this.$eventHub.$emit('select-tab', 'Trends');
				this.$router.push("/trends");
			}
			if (this.key.name == "Eyekeya") {
				// Toggle Newbie flag
				this.$services.me.status = this.$services.me.status == 'active' ? 'newbie' : 'active';
				await this.$services.get("users/me/noob");
				this.$eventHub.$emit('select-tab', 'Next');
				this.$router.push("/choice/next");
			}
			if (this.key.name == "Community") {
				const groups = await this.$services.getAll("groups");
				this.$eventHub.$emit('select-tab', 'Score');
				this.$router.push(`/group/${groups[0].id}`); 
			}
		}
	}
}

</script>

<style scoped>
.key-info {
	text-align: center;
}
.header {
	color: #99ccff;
	font-size: 26px;
	margin: 5px;
	text-align: center;
}

.newKey {
	display: block;
	font-size: 26px;
	margin: 3px;
	text-align: center;
	color: green;
}

.keyBox {
	display: block;
	text-align: center;
}
.keyImage {
	align-content: center;
	margin-top: 5px;
	max-height: 60vh;
}

.info {
	margin: 2px;
	font-size: 18px;
	text-align: center;
}
</style>
